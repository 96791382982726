<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row class="mt-3">
                        <!-- Name -->
                        <b-col md="12">
                            <!-- <b-form-group>
                                <h5>Multiple Dates</h5>
                                <flat-pickr v-model="form.multiDate" class="form-control" :config="{ mode: 'multiple', dateFormat: 'Y-m-d' }" />
                            </b-form-group> -->
                            <b-row class="my-3 mb-4">
                                <b-col cols="6">
                                    <label for="example-datepicker">From</label>
                                    <b-form-datepicker id="example-datepicker" v-model="form.from" class="mb-1" />
                                </b-col>
                                <b-col cols="6">
                                    <label for="example-datepicker">To</label>
                                    <b-form-datepicker id="example-datepicker" v-model="form.to" class="mb-1" />
                                </b-col>
                            </b-row>
                        </b-col>

                        <!-- Chart -->
                        <b-col class="text-center" cols="12">
                            <b-button-group>
                                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"> Day </b-button>
                                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"> Mounth </b-button>
                                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"> Year </b-button>
                            </b-button-group>
                        </b-col>
                        <b-col cols="12">
                            <apexchart class="col-md-12" type="area" height="350" :options="chartOptions" :series="series"></apexchart>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </template>
    </component>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import { BFormDatepicker, BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButtonGroup, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useSlideShowList from "./useSlideShowList";

const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BTab,
        BFormInput,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        apexchart: VueApexCharts,
        flatPickr,
        BFormDatepicker,
        BButtonGroup,
    },
    directives: {
        Ripple,
    },
    setup() {
        let blankForm = {
            from: "",
            to: "",
        };

        const playlistId = ref(null);

        const form = ref(JSON.parse(JSON.stringify(blankForm)));
        const sourceOption = ref(["artists", "music", "videos", "playlists"]);
        if (router.currentRoute.params.id) {
            playlistId.value = router.currentRoute.params.id;
        }

        const { storeSlideShow, updatePlaylist, showPlaylist } = useSlideShowList();
        // Register module

        const onSubmit = async () => {
            console.log(form);
            console.log(storeSlideShow(form.value));
            storeSlideShow(form.value);
            // storePlaylist(form.value);
            // if (fileForm.value.file) {
            //     await storeFile()
            // }
            // if (playlistId.value) {
            //     updatePlaylist(playlistId.value, form.value)
            // } else {
            //     storePlaylist(form.value)
            // }
            // router.push({ name: 'apps-moods-list' })
        };

        // Reset Validation
        const resetData = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

        // fill Form When edit page
        const showData = async () => {
            const response = await showPlaylist(playlistId.value);
            if (response) {
                form.value = { ...blankForm, ...response };
                blankForm = { ...blankForm, ...response };
            } else {
                form.value = undefined;
            }
        };

        if (playlistId.value) {
            showData();
        }
        //apexchart
        const series = ref([
            {
                name: "series1",
                data: [31, 40, 28, 51, 42, 109, 100],
            },
            {
                name: "series2",
                data: [11, 32, 45, 32, 34, 52, 41],
            },
            {
                name: "series2",
                data: [11, 32, 50, 35, 34, 52, 10],
            },
        ]);
        const chartOptions = ref({
            chart: {
                height: 350,
                type: "area",
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },
            xaxis: {
                type: "datetime",
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
            },
            tooltip: {
                x: {
                    format: "dd/MM/yy HH:mm",
                },
            },
        });
        return {
            form,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            required,
            playlistId,
            sourceOption,
            series,
            chartOptions,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
